import cn from 'classnames';
import ru from 'date-fns/locale/ru';
import type dayjs from 'dayjs';
import { useCallback, useContext, useEffect, useMemo, useRef, useState } from 'react';
import ReactDatePicker, { registerLocale } from 'react-datepicker';

import 'react-datepicker/dist/react-datepicker.css?CSSModulesDisable';
import { useMobile } from '@webapp/common/hooks/use-mobile';
import { MONTHS } from '@webapp/common/lib/date';

import { CustomStylesCtx } from '../custom-styles';
import { Arrow } from '../icons';

import { Groups } from './groups';
import { DateScale, getMonthGroups, getTitle, getYearGroups, YEARS_RANGE } from './util';
import type { Params } from './util';

import css from './date-picker.css';

registerLocale('ru', ru);

export const DatePicker: FC<{
    selected?: Date;
    onChange: (date: Date) => void;
    customInput?: ReactNode;
    minDate?: dayjs.Dayjs;
    maxDate?: dayjs.Dayjs;
    dateFormat?: string;
    className?: string;
    placeholder?: string;
}> = ({ className, customInput, dateFormat, maxDate, minDate, onChange, placeholder, selected }) => {
    const inputRef = useRef<any>();
    const [type, setType] = useState<DateScale | null>(DateScale.DAY);
    const mobile = useMobile();

    const selectType = useCallback(() => {
        switch (type) {
            case DateScale.DAY:
                setType(DateScale.MONTH);
                break;
            case DateScale.MONTH:
                setType(DateScale.YEAR);
                break;
        }
    }, [type]);

    const prev = useCallback(
        (params: Params): void => {
            switch (type) {
                case DateScale.DAY:
                    params.decreaseMonth();
                    break;
                case DateScale.MONTH:
                    params.changeYear(params.date.getFullYear() - 1);
                    break;
                default:
                    params.changeYear(params.date.getFullYear() - YEARS_RANGE);
                    break;
            }
        },
        [type]
    );

    const next = useCallback(
        (params: Params): void => {
            switch (type) {
                case DateScale.DAY:
                    params.increaseMonth();
                    break;
                case DateScale.MONTH:
                    params.changeYear(params.date.getFullYear() + 1);
                    break;
                default:
                    params.changeYear(params.date.getFullYear() + YEARS_RANGE);
                    break;
            }
        },
        [type]
    );

    const selectYear = useCallback((params: Params, year: number) => {
        params.changeYear(year);
        setType(DateScale.MONTH);
    }, []);

    const selectMonth = useCallback((params: Params, month: number) => {
        params.changeMonth(month);
        setType(DateScale.DAY);
    }, []);

    const { datePicker } = useContext(CustomStylesCtx);

    // eslint-disable-next-line react-hooks/exhaustive-deps
    const min = useMemo(() => minDate && minDate.toDate(), [minDate && minDate.toString()]);
    // eslint-disable-next-line react-hooks/exhaustive-deps
    const max = useMemo(() => maxDate && maxDate.toDate(), [maxDate && maxDate.toString()]);

    // useEffect(() => {
    //     const input = inputRef.current?.input;
    //     if (input && !mobile) {
    //         const blurHanlder = () => {
    //             setTimeout(() => {
    //                 inputRef.current?.setOpen(false);
    //             }, 0);
    //         };
    //         input.addEventListener('blur', blurHanlder);
    //         return () => {
    //             input.removeEventListener('blur', blurHanlder);
    //         };
    //     }
    // }, [inputRef, mobile]);

    const renderCustomHeader = useCallback(
        (params: Params) => (
            <>
                <div className={css.head}>
                    <div className={css.nav} style={datePicker?.arrow} onClick={() => prev(params)}>
                        <Arrow className={css.icon} />
                    </div>
                    <div className={css.title} onClick={selectType}>
                        {getTitle(type, params)}
                    </div>
                    <div className={css.nav} style={datePicker?.arrow} onClick={() => next(params)}>
                        <Arrow className={cn(css.icon, css.next)} />
                    </div>
                </div>
                {type === DateScale.MONTH && (
                    <Groups
                        groups={getMonthGroups()}
                        renderCell={(num) => MONTHS[num].slice(0, 3)}
                        onSelect={(month) => selectMonth(params, month)}
                    />
                )}
                {type === DateScale.YEAR && (
                    <Groups
                        groups={getYearGroups(params.date.getFullYear())}
                        renderCell={(num) => String(num)}
                        onSelect={(year) => selectYear(params, year)}
                    />
                )}
            </>
        ),
        [datePicker?.arrow, next, prev, selectMonth, selectType, selectYear, type]
    );

    return (
        <div style={datePicker?.root}>
            <ReactDatePicker
                ref={inputRef}
                customInput={customInput}
                dateFormat={dateFormat}
                disabledKeyboardNavigation={true}
                locale='ru'
                maxDate={max}
                minDate={min}
                placeholderText={placeholder}
                popperClassName={css.popper}
                popperPlacement='right-start'
                portalId='rdp-portal'
                renderCustomHeader={renderCustomHeader}
                selected={selected}
                withPortal={mobile} // TODO provide portal custom styles
                calendarClassName={cn({
                    [css.calendar]: true
                })}
                className={cn({
                    [className]: !!className
                })}
                onChange={onChange}
            />
        </div>
    );
};
